jQuery(document).ready(() => {
  jQuery(document).previewUploadedImageAndCheckSize('#app_page_images');
  jQuery(document).previewUploadedImageAndCheckSize('#app_video_images');
  jQuery(document).previewUploadedVideoAndCheckSize('#app_video_files');
  jQuery(document).previewUploadedFileAndCheckSize('#madcoders_rma_order_return_file');
  jQuery('form[name=madcoders_rma_order_return_file]').each(function() {
    jQuery(document).previewUploadedFileAndCheckSize('#'+jQuery(this).attr('id'));
  });
  jQuery(document).previewUploadedImageAndCheckSize('#app_page_translations_fr_FR_images');
  jQuery(document).previewUploadedImageAndCheckSize('#app_page_translations_en_EN_images');
  jQuery(document).previewUploadedImageAndCheckSize('#app_page_translations_de_DE_images');
  jQuery(document).previewUploadedImageAndCheckSize('#app_page_translations_it_IT_images');
  jQuery(document).previewUploadedImageAndCheckSize('#app_page_translations_es_ES_images');
  jQuery(document).previewUploadedImageAndCheckSize('#app_page_translations_en_US_images');
  jQuery(document).previewUploadedImageAndCheckSize('#app_page_translations_ja_JP_images');
  jQuery(document).previewUploadedImageAndCheckSize('#app_news_images');
  jQuery(document).previewUploadedImageAndCheckSize('#app_menu_item_images');
  jQuery(document).previewUploadedImageAndCheckSize('#app_feature_images');
  jQuery(document).previewUploadedImageAndCheckSize('#app_athlete_images');
  jQuery(document).pageSlugGenerator();
  jQuery(document).copyParagraphs();
  jQuery(document).externLink();
  if (jQuery('form[name=sylius_admin_order_creation_new_order]').length > 0) {
    jQuery(document).provinceField();
  }
  jQuery(document).autocompleteProductField('form[name=sylius_channel]');
  jQuery(document).sortProductVariantField('#sylius_channel_productVariants');
});

const getProvinceInputValue = function getProvinceInputValue(valueSelector) {
  return valueSelector == undefined ? '' : 'value="' + valueSelector + '"';
};

const updatePageSlug = function updatePageSlug(element, element_page_parente) {
  const slugInput = element.parents('.content').find('[name*="[slug]"]');
  const loadableParent = slugInput.parents('.field.loadable');

  if (slugInput.attr('readonly') === 'readonly') {
    return;
  }

  loadableParent.addClass('loading');

  $.ajax({
    type: 'GET',
    url: slugInput.attr('data-url'),
    data: { title: element.val(), parent: element_page_parente ? element_page_parente.val() : null, locale: slugInput.attr('data-locale') },
    dataType: 'json',
    accept: 'application/json',
    success(response) {
      slugInput.val(response.slug);
      if (slugInput.parents('.field').hasClass('error')) {
        slugInput.parents('.field').removeClass('error');
        slugInput.parents('.field').find('.sylius-validation-error').remove();
      }
      loadableParent.removeClass('loading');
    },
  });
};

const updateAthleteSlug = function updateAthleteSlug(val, slugInput) {
  const loadableParent = slugInput.parents('.field.loadable');

  if (slugInput.attr('readonly') === 'readonly') {
    return;
  }

  loadableParent.addClass('loading');

  $.ajax({
    type: 'GET',
    url: slugInput.attr('data-url'),
    data: { name: val, locale: slugInput.attr('data-locale') },
    dataType: 'json',
    accept: 'application/json',
    success(response) {
      slugInput.val(response.slug);
      if (slugInput.parents('.field').hasClass('error')) {
        slugInput.parents('.field').removeClass('error');
        slugInput.parents('.field').find('.sylius-validation-error').remove();
      }
      loadableParent.removeClass('loading');
    },
  });
};

const togglePageSlugModification = function togglePageSlugModification(button, slugInput) {
  if (slugInput.attr('readonly')) {
    slugInput.removeAttr('readonly');
    button.html('<i class="unlock icon"></i>');
  } else {
    slugInput.attr('readonly', 'readonly');
    button.html('<i class="lock icon"></i>');
  }
};

const displayUploadedImageAndCheckSize = function displayUploadedImageAndCheckSize(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (event) {
      var image = jQuery(input).parent().siblings('.image');

      if (image.length > 0) {
        image.attr('src', event.target.result);
      } else {
        var img = jQuery('<img class="ui small bordered image"/>');
        img.attr('src', event.target.result);
        jQuery(input).parent().before(img);
      }
    };

    function displayErrorFile(message) {
      var error = jQuery(input).parent().siblings('.error');

      if (error.length > 0) {
        error.html(message).addClass('visible').removeClass('hidden');
      } else {
        var err = jQuery('<div class="ui error message visible"/>');
        err.html(message);
        jQuery(input).parent().before(err);
      }
    };

    function hideErrorFile() {
      var error = jQuery(input).parent().siblings('.error');

      if (error.length > 0) {
        error.html('').addClass('hidden').removeClass('visible');
      }
    };

    var allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    var size = parseFloat(input.files[0].size / 1024 / 1024);
    var type = input.files[0].type;
    var size_max = parseFloat(jQuery(input).attr('data-max')?? 2);
    if(size>size_max) displayErrorFile('Taille d\'image incorrecte. Limitée à '+size_max+'Mo.');
    else if(!allowedTypes.includes(type)) displayErrorFile('Type de fichier incorrect. Limité aux formats JPEG, PNG ou GIF.');
    else {
      hideErrorFile();
      reader.readAsDataURL(input.files[0]);
    }
  }
};

const displayUploadedFileAndCheckSize = function displayUploadedFileAndCheckSize(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (event) {
      console.log('load file');
    };

    function displayErrorFile(message) {
      var error = jQuery(input).parent().siblings('.error');

      if (error.length > 0) {
        error.html(message).addClass('visible').removeClass('hidden');
      } else {
        var err = jQuery('<div class="ui error message visible"/>');
        err.html(message);
        jQuery(input).parent().before(err);
      }
    };

    function hideErrorFile() {
      var error = jQuery(input).parent().siblings('.error');

      if (error.length > 0) {
        error.html('').addClass('hidden').removeClass('visible');
      }
    };

    var allowedTypes = ['application/pdf'];
    var size = parseFloat(input.files[0].size / 1024 / 1024);
    var type = input.files[0].type;
    var size_max = parseFloat(jQuery(input).attr('data-max')?? 2);
    if(size>size_max) displayErrorFile('Taille de fichier incorrecte. Limitée à '+size_max+'Mo.');
    else if(!allowedTypes.includes(type)) displayErrorFile('Type de fichier incorrect. Limité aux formats PDF.');
    else {
      hideErrorFile();
      reader.readAsDataURL(input.files[0]);
    }
  }
};

const displayUploadedVideoAndCheckSize = function displayUploadedVideoAndCheckSize(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (event) {
      console.log('load video');
    };

    function displayErrorFile(message) {
      var error = jQuery(input).parent().siblings('.error');

      if (error.length > 0) {
        error.html(message).addClass('visible').removeClass('hidden');
      } else {
        var err = jQuery('<div class="ui error message visible"/>');
        err.html(message);
        jQuery(input).parent().before(err);
      }
    };

    function hideErrorFile() {
      var error = jQuery(input).parent().siblings('.error');

      if (error.length > 0) {
        error.html('').addClass('hidden').removeClass('visible');
      }
    };

    var allowedTypes = ['video/mp4'];
    var size = parseFloat(input.files[0].size / 1024 / 1024);
    var type = input.files[0].type;
    var size_max = parseFloat(jQuery(input).attr('data-max')?? 2);
    if(size>size_max) displayErrorFile('Taille de vidéo incorrecte. Limitée à '+size_max+'Mo.');
    else if(!allowedTypes.includes(type)) displayErrorFile('Type de fichier incorrect. Limité aux formats MP4.');
    else {
      hideErrorFile();
      reader.readAsDataURL(input.files[0]);
    }
  }
};

jQuery.fn.extend({
  pageSlugGenerator: function pageSlugGenerator(field) {
    let timeout;

    $('[name*="app_page[translations]"][name*="[title]"]').on('input', (event) => {
      clearTimeout(timeout);
      const element = jQuery(event.currentTarget);
      const element_page_parente = jQuery('#app_page_parent');

      timeout = setTimeout(() => {
        updatePageSlug(element, element_page_parente);
      }, 1000);
    });

    $('[name*="app_news_category[translations]"][name*="[title]"]').on('input', (event) => {
      clearTimeout(timeout);
      const element = jQuery(event.currentTarget);

      timeout = setTimeout(() => {
        updatePageSlug(element, null);
      }, 1000);
    });

    $('[name*="app_news[translations]"][name*="[title]"]').on('input', (event) => {
      clearTimeout(timeout);
      const element = jQuery(event.currentTarget);
      const element_page_parente = jQuery('input[name^="app_news[categories]"]:checked').eq(0);

      timeout = setTimeout(() => {
        updatePageSlug(element, element_page_parente);
      }, 1000);
    });

    $('#app_athlete_name').on('input', (event) => {
      clearTimeout(timeout);
      const nameVal = jQuery(event.currentTarget).val();

      timeout = setTimeout(() => {
        $( '[name*="app_athlete[translations]"][name*="[slug]"]' ).each(function( index ) {
          updateAthleteSlug(nameVal, $(this));
        });
      }, 1000);
    });


    jQuery('.toggle-page-slug-modification').on('click', (event) => {
      event.preventDefault();
      togglePageSlugModification(jQuery(event.currentTarget), jQuery(event.currentTarget).siblings('input'));
    });
  },
  externLink: function externLink(field) {
    let timeout;

    $(document).on('input', '.header-link input.search', function(event) {
      clearTimeout(timeout);
      const element = jQuery(event.currentTarget);

      timeout = setTimeout(() => {
        $(element).parent().find('input.autocomplete').val(element.val());
      }, 1000);
    });
  },
  copyParagraphs: function copyParagraphs(field) {
    const originParagraphFR = $("input[id$='fr_FR_paragraphs']");
    const originParagraphUS = $("input[id$='en_US_paragraphs']");
    if(originParagraphFR.length && originParagraphUS.length) {
      $("input[id$='_paragraphs']").each(function () {
        const nameAction = $(this).attr('id') + "-action";

        var contentHtml = "<div class='ui container center aligned segment'>";
        if ($(this).attr('id').includes('fr_FR') == false) {
          contentHtml += "<button id='" + nameAction + "-fr' data-source='fr' class='ui button'>Dupliquer à partir du FR</button>";
        }
        if ($(this).attr('id').includes('en_US') == false) {
          contentHtml += "<button id='" + nameAction + "-us' data-source='us' class='ui button'>Dupliquer à partir du US</button>";
        }
        contentHtml += "</div>",

          $(contentHtml).insertBefore($(this));
        const currentParagraph = $(this);

        $('body').on('click', '#' + nameAction + '-fr, #' + nameAction + '-us', function (event) {
          const currentAction = $(this);
          currentAction.prop("disabled", true);
          event.preventDefault();
          if ($(this).data('source') == 'fr') {
            currentParagraph.val(originParagraphFR.val());
          } else {
            currentParagraph.val(originParagraphUS.val());
          }

          currentParagraph.parent().find('.container').last().remove();
          reloadEditor(currentParagraph.attr('name'));
          setTimeout(() => {
            currentAction.prop("disabled", false);
          }, 1000);
        });
      });
    }
  },
  previewUploadedImageAndCheckSize: function previewUploadedImageAndCheckSize(root) {
    jQuery(root).on('change', 'input[type="file"]', function () {
      displayUploadedImageAndCheckSize(this);
    });
  },
  previewUploadedFileAndCheckSize: function previewUploadedFileAndCheckSize(root) {
    jQuery(root).on('change', 'input[type="file"]', function () {
      displayUploadedFileAndCheckSize(this);
    });
  },
  previewUploadedVideoAndCheckSize: function previewUploadedVideoAndCheckSize(root) {
    jQuery(root).on('change', 'input[type="file"]', function () {
      displayUploadedVideoAndCheckSize(this);
    });
  },
  provinceField: function provinceField() {
    var countrySelect = jQuery('select[name$="[countryCode]"]');

    countrySelect.on('change', function (event) {
      var select = jQuery(event.currentTarget);
      var provinceContainer = select.parents('.field').next('div.province-container');

      var provinceSelectFieldName = select.attr('name').replace('country', 'province');
      var provinceInputFieldName = select.attr('name').replace('countryCode', 'provinceName');

      var provinceSelectFieldId = select.attr('id').replace('country', 'province');
      var provinceInputFieldId = select.attr('id').replace('countryCode', 'provinceName');

      var form = select.parents('form');

      if (select.val() === '' || select.val() == undefined) {
        provinceContainer.fadeOut('slow', function () {
          provinceContainer.html('');
        });

        return;
      }

      provinceContainer.attr('data-loading', true);
      form.addClass('loading');

      jQuery.get(provinceContainer.attr('data-url'), { countryCode: select.val() }, function (response) {
        if (!response.content) {
          provinceContainer.fadeOut('slow', function () {
            provinceContainer.html('');

            provinceContainer.removeAttr('data-loading');
            form.removeClass('loading');
          });
        } else if (response.content.indexOf('select') !== -1) {
          provinceContainer.fadeOut('slow', function () {
            var provinceSelectValue = getProvinceInputValue(jQuery(provinceContainer).find('select > option[selected$="selected"]').val());

            provinceContainer.html(response.content.replace('name="sylius_address_province"', 'name="' + provinceSelectFieldName + '"' + provinceSelectValue).replace('id="sylius_address_province"', 'id="' + provinceSelectFieldId + '"').replace('option value="" selected="selected"', 'option value=""').replace('option ' + provinceSelectValue, 'option ' + provinceSelectValue + '" selected="selected"'));

            provinceContainer.removeAttr('data-loading');

            provinceContainer.fadeIn('fast', function () {
              form.removeClass('loading');
            });
          });
        } else {
          provinceContainer.fadeOut('slow', function () {
            var provinceInputValue = getProvinceInputValue(jQuery(provinceContainer).find('input').val());

            provinceContainer.html(response.content.replace('name="sylius_address_province"', 'name="' + provinceInputFieldName + '"' + provinceInputValue).replace('id="sylius_address_province"', 'id="' + provinceInputFieldId + '"'));

            provinceContainer.removeAttr('data-loading');

            provinceContainer.fadeIn('fast', function () {
              form.removeClass('loading');
            });
          });
        }
      });
    });

    if (countrySelect.val() !== '') {
      countrySelect.trigger('change');
    }

    if (jQuery.trim(jQuery('div.province-container').text()) === '') {
      jQuery('select.country-select').trigger('change');
    }

    var shippingAddressCheckbox = jQuery('input[type="checkbox"][name$="[differentShippingAddress]"]');
    var shippingAddressContainer = jQuery('#sylius-shipping-address-container');
    var toggleShippingAddress = function toggleShippingAddress() {
      shippingAddressContainer.toggle(shippingAddressCheckbox.prop('checked'));
    };
    toggleShippingAddress();
    shippingAddressCheckbox.on('change', toggleShippingAddress);
  },
  autocompleteProductField: function autocompleteProductField(field) {
    jQuery(field).find('.sylius-autocomplete').each(function( index, sort ) {
      $(sort).sortable(
        {
          items: '> a.label',
          stop: function (event, ui) {
            var values = [];
            $(sort).find('a.label').each(function (index, elem) {
              values.push($(elem).data('value'));
            });
            $(sort).find('input[type=hidden]').val(values.join(','));
          }
        });
    });
  },
  sortProductVariantField: function sortProductVariantField(field) {
    jQuery(field).find('[data-form-collection=list]').each(function( index, sort ) {

      $(sort).find('[data-form-collection=item]').each(function (index2, elem) {
        $(elem).prepend('<i class="sort icon"></i>');
        $(elem).find('input[type=number]').attr('value',index2);
      });

      $(sort).sortable(
        {
          items: '> [data-form-collection=item]',
          placeholder: 'ui-state-highlight',
          stop: function (event, ui) {
            $(sort).find('[data-form-collection=item]').each(function (index2, elem) {
              $(elem).find('input[type=number]').attr('value',index2);
            });
          }
        }
      );
      $(document).on('collection-form-delete', (event, addedElement) => {
        $(sort).find('[data-form-collection=item]').each(function (index2, elem) {
          $(elem).find('input[type=number]').attr('value',index2);
        });
        $(sort).sortable('refresh');
      });
      $(document).on('collection-form-add', (event, addedElement) => {
        $(sort).find('[data-form-collection=item]').each(function (index2, elem) {
          $(elem).find('input[type=number]').attr('value',index2);
          $(elem).find('.sort').remove();
          $(elem).prepend('<i class="sort icon"></i>');
        });
        $(sort).sortable('refresh');
      });

    });
  }
});
