import logo from './images/logo.svg';
import text_taxon_seo from './images/UIElement/text_taxon_seo.jpg';
import text_image from './images/UIElement/text_image.jpg';
import text from './images/UIElement/text.jpg';
import image from './images/UIElement/image.jpg';
import quote from './images/UIElement/quote.jpg';
import teams from './images/UIElement/teams.jpg';
import teams2 from './images/UIElement/teams2.jpg';
import carousel from './images/UIElement/carousel.jpg';
import products from './images/UIElement/products.jpg';
import dontmiss from './images/UIElement/dontmiss.jpg';
import wearemavic from './images/UIElement/wearemavic.jpg';
import gallery from './images/UIElement/gallery.jpg';
import gallery2 from './images/UIElement/gallery2.jpg';
import link from './images/UIElement/link.jpg';
import links from './images/UIElement/links.jpg';
import iframe from './images/UIElement/iframe.jpg';
import form from './images/UIElement/form.jpg';
import fullscreen from './images/UIElement/fullscreen.jpg';
import columns from './images/UIElement/columns.jpg';
import faq_categories from './images/UIElement/faq_categories.jpg';
import files from './images/UIElement/files.jpg';
import images from './images/UIElement/images.jpg';
import slide from './images/UIElement/slide.jpg';
import video from './images/UIElement/video.jpg';
import news from './images/UIElement/news.jpg';
import events from './images/UIElement/events.jpg';
import two_columns from './images/UIElement/two_columns.jpg';
import logo_mail_header from './images/mail/logo-mail-header.svg';
import logo_mailfooter from './images/mail/logo-mail-footer.svg';
import admin_js from './js/admin.js';
import admin_css from './css/admin.css';
